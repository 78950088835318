@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

#granim-canvas {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -9999;
}

#logo:hover {
  transform: scale(2);
}
* {
  transition: box-shadow 0.2s ease, background-color 0.2s ease, filter 0.2s ease,
    transform 0.15s ease;
  @apply rtl:font-Cairo font-Poppins;
}
/* .header-border {
  box-shadow: 2px 2px 0 3px #ef4cce, 4px 4px 0 6px #efd94c,
    6px 6px 0 8px #1568e6;
  transition: box-shadow 0.2s ease;
} */
/* .header-border:hover {
  box-shadow: -2px -2px 0 4px #ef4cce, -4px -4px 0 6px #efd94c,
    -6px -6px 0 8px #1568e6, 0 0 10px 5px #efd94c;
}
#button {
  box-shadow: 0 4px 0 0px #ef4cce, 0 6px 0 3px #111, 0 8px 0 4px #efd94c;
}
 */
.twitter-button {
  border: #2daae1 solid 2px;
  color: #2daae1;
  background-color: #ffffff;
  transition: all 100ms;
}
.twitter-button:hover {
  border: hsl(198, 75%, 50%) solid 2px;
  color: hsl(198, 75%, 50%);
  background-color: hsl(0, 0%, 97%);
}
/* #button:hover {
  background-color: #1568e6;
  box-shadow: 0 6px 0 0px #1568e6, 5px -4px 0 3px #ef4cce,
    10px -10px 0 0px #efd94c, 0 8px 10px 0 #1568e6;
} */
#down {
  transform: translateY(-0.2rem);
  animation: alternate infinite down ease-in-out 1s;
}
@keyframes down {
  0% {
    transform: translateY(0.2rem) scaleY(1.3);
  }

  100% {
    transform: translateY(-0.2rem) scaleY(1);
  }
}
/* .question:hover {
  filter: drop-shadow(-2px 3px 0 #ef4cce) drop-shadow(2px -3px 0 #1568e6);
} */
